import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { animateScroll as scroll } from "react-scroll";
import about from "./../../components/images/about.png";
import { Link } from "react-router-dom";

const contentVariants = {
    initial: {
      translateY: "100vh",
      opacity: 0,
    },
  
    animate: {
      translateY: "0vh",
      opacity: 1,
      transition: {
        duration: 2,
        when: "beforeChildren",
        // staggerChildren: 0.4,
      },
    },
  };
  
  
function About() {

    const boxVariants = {
        hover: {
          scale: 1.06,
          transition: {
            type: "spring",
          },
        },
      };
      
    return (
     

        <section
        name="about"
        id="about"
        className='about'
        >
            <div className='container'  >
            <div class="row flex-space-around">
            <div className='about-left' >
            <h5>ABOUT</h5>
            <h3>Innovating the Future: The DAGTECH Journey</h3>
            <p>
            DAGTECH CAP LIMITED is a dynamic and innovative technology company established in August 2023. With a passion for excellence and a commitment to technological advancement, we are at the forefront
            of providing a range of tech solutions.   
            </p>
            <Link
              className=""
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              delay={100}
              offset={0}
              duration={500}
              >
                <button className="bordered-rounded-button">Learn More <i className='fa fa-arrow-right' /></button>
                </Link>
            </div>
            <div className='about-right'>
            <img src={about} className='about-image' />

            </div>
        </div>
        </div>
    </section>

        );
    }    
export default About;    
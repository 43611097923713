import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link, animateScroll as scroll } from 'react-scroll';
import polygon from "./../../components/images/polygon.png";

const contentVariants = {
    initial: {
      translateY: 0, // Set the initial position at the resting point
    },
    animate: {
      translateY: [-20, 0, -20, 0], // Animate between -20 and 0 (up and down)
      transition: {
        yoyo: Infinity, // Continuously repeats the animation
        duration: 6, // Animation duration in seconds
      },
    },
  };
  
function Commitment() {
    return (
    <section
    className='commitment'
    >
        <div className='commitment-wrapper'>
            <h3>Our Commitment</h3>
            <p>
            Innovation: We pride ourselves on being at the forefront of technological advancement, consistently pushing the boundaries to develop and deliver innovative solutions that address real-world challenges.
            </p>
            <p>
            Impact in Africa: Our roots run deep in Africa, and we are committed to playing a pivotal role in driving technological progress across the continent. We believe that technology has the power to transform lives, industries, and economies in Africa and have dedicated our efforts to this cause.
            </p>
            <p>
            Unlocking Potentials: At DAGTECH CAP LIMITED, we see potential everywhere. We are committed to unlocking the untapped possibilities in the digital world, whether through our cross-border payment solutions, web hosting services, or telecommunication offerings
            </p>
        </div>
        
    </section>
  );
}
export default Commitment;
import React, { useState } from "react";

function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (formData.firstName.trim() === "") {
      errors.firstName = "First name is required";
    }

    if (formData.lastName.trim() === "") {
      errors.lastName = "Last name is required";
    }

    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (formData.subject.trim() === "") {
      errors.subject = "Subject is required";
    }

    if (formData.message.trim() === "") {
      errors.message = "Message is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission, e.g., send data to the server
      console.log("Form submitted:", formData);
    } else {
      console.log("Form has errors. Please correct them.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
      <div className="form-wrapper">
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          <span className="error">{formErrors.firstName}</span>
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
          <span className="error">{formErrors.lastName}</span>
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <span className="error">{formErrors.email}</span>
        </div>

        <div className="form-group">
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
          <span className="error">{formErrors.subject}</span>
        </div>

       
        </div>
        <div className="form-group-textarea">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <span className="error">{formErrors.message}</span>
        </div>
        <button type="submit" className="btn-primary">Send</button>

      </form>
    </div>
  );
}

export default ContactForm;

import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { animateScroll as scroll } from "react-scroll";
import david from "./../../components/images/david.png";
import esquire from "./../../components/images/esquire.png";
import alice from "./../../components/images/alice.png";

import team from "./../../components/images/team.png";
import { Link } from "react-router-dom";

const contentVariants = {
    initial: {
      translateY: "100vh",
      opacity: 0,
    },
  
    animate: {
      translateY: "0vh",
      opacity: 1,
      transition: {
        duration: 2,
        when: "beforeChildren",
        // staggerChildren: 0.4,
      },
    },
  };
  
  
function AllTeam() {

    const boxVariants = {
        hover: {
          scale: 1.06,
          transition: {
            type: "spring",
          },
        },
      };
      
    return (
     

        <section
        name="team"
        id="about"
        className='team'
        >
            <div className='container'>
            <h2>Meet Our Team</h2>
            <p>
            Get to know the dedicated individuals who drive <br /> our vision forward.
            </p>
            <div class="team-container">
               <div class="team-item">
               <img src={david}  />
               <h3>David Agarrey</h3>
                 <span>CEO</span>
                 <p>
                 Visionary leader driving strategic growth and innovation, ensuring our mission resonates throughout the company.
                 </p>
                 <div className='row team-social'>
                    <a href=""><i className='fa fa-twitter s-link' /></a>
                    <a href=""><i className='fa fa-linkedin s-link' /></a>
                   </div>
               </div>
               <div class="team-item">
               <img src={esquire}  />
               <h3>Akpobaro Esquire</h3>
                 <span>COO</span>
                 <p>
                 Operational expert overseeing daily activities and processes, committed to efficiency and delivering value to our audience.
                 </p>
                   <div className='row team-social'>
                    <a href=""><i className='fa fa-twitter s-link' /></a>
                    <a href=""><i className='fa fa-linkedin s-link' /></a>
                   </div>
               </div>
               <div class="team-item">
               <img src={team}  />
               <h3>Kehinde Olarewaju</h3>
                 <span>LEAD</span>
                 <p>
                 Project guide fostering collaboration and creativity, ensuring alignment with our vision through regular updates.
                 </p>
                 <div className='row team-social'>
                    <a href=""><i className='fa fa-twitter s-link' /></a>
                    <a href=""><i className='fa fa-linkedin s-link' /></a>
                   </div>
               </div>
               <div class="team-item">
               <img src={team}  />
               <h3>Shedrach Onuh</h3>
                 <span>CTO</span>
                 <p>
                 Technical mastermind advancing our technology, focused on delivering exceptional solutions and insights.
                 </p>
                 <div className='row team-social'>
                    <a href=""><i className='fa fa-twitter s-link' /></a>
                    <a href=""><i className='fa fa-linkedin s-link' /></a>
                   </div>
               </div>
               <div class="team-item">
               <img src={team}  />
               <h3>Precious Omagbemi</h3>
                 <span>Fullstack Engineer</span>
                 <p>
                 Versatile engineer bridging front-end and back-end development, creating seamless user experiences.
                 </p>
                 <div className='row team-social'>
                    <a href=""><i className='fa fa-twitter s-link' /></a>
                    <a href=""><i className='fa fa-linkedin s-link' /></a>
                   </div>
               </div>
               <div class="team-item">
               <img src={alice}  />
               <h3>Oluwatunmise Adetayo</h3>
                 <span>UI/UX Designer</span>
                 <p>
                 Creative designer crafting intuitive and engaging experiences, ensuring functionality and aesthetics go hand in hand.
                 </p>
                 <div className='row team-social'>
                    <a href=""><i className='fa fa-twitter s-link' /></a>
                    <a href=""><i className='fa fa-linkedin s-link' /></a>
                   </div>
               </div>
            </div>
        </div>
    </section>

        );
    }    
export default AllTeam;    
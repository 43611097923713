import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link, animateScroll as scroll } from 'react-scroll';
import hero from "./../../components/images/hero.png";

const contentVariants = {
    initial: {
      translateY: 0, // Set the initial position at the resting point
    },
    animate: {
      translateY: [-20, 0, -20, 0], // Animate between -20 and 0 (up and down)
      transition: {
        yoyo: Infinity, // Continuously repeats the animation
        duration: 6, // Animation duration in seconds
      },
    },
  };
  
function Welcome() {
    return (
    <section
    className='welcome'
    data-aos="fade-down" 
    name="home"
    id="home"
    >
        <div class="row">
            <div className='hero-left'>
            <h5>Welcome to DAGTECH CAP LIMITED</h5>
            <h3>Empowering Tomorrow's Tech Today.</h3>
            <p>At DAGTECH CAP LIMITED, we are dedicated to harnessing the power of technology to transform industries,
               drive innovation, and unlock the untapped potentials of tomorrow.</p>
              
                <Link
                className=""
                to="/about"
                >
                <button className="bordered-rounded-button">Learn More</button>
                </Link>
            </div>
            <div className='hero-right'>
            <motion.div
            className=''
            animate='animate'
            variants={contentVariants}
          >
            <img src={hero} className='hero-image' />
          </motion.div>
            </div>
        </div>
       
    </section>
  );
}
export default Welcome;
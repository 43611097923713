import React from "react";
import NavBar from "../Navbar";
import Footer from "../Footer";
import AllTeam from "./Team";
import Breadcrumb from "../Breadcrumb";
import banner from './../images/team-b.png';
function TeamScreen() {

  return (
    <React.Fragment>
        <div className="container">
            <NavBar />
            <Breadcrumb page={'Team'} title={'The Team'} banner={banner}  />
           <AllTeam />
        </div>
        <Footer />

    </React.Fragment>
  );
}

export default TeamScreen;
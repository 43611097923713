import React, { Component,useState,useEffect } from 'react';
import telecom from "./../../components/images/telecom.png";
import { Link } from "react-router-dom";

  
function TeleCommunication() {


    return (
        <section
        name="products"
        className='products bg-grey'
        >
            <div className='container'  >
            <div class="row flex-space-around products-row">
            <div className='row-left' >
            <h5>Telecommunications Services</h5>
            <h3>Connecting You to the World: DAGTECH CAP LIMITED Telecommunications Services</h3>
            <p>
            DAGTECH CAP LIMITED is your partner in staying connected. Our telecommunications services include virtual top-up, data bundle sales, and utilities bill payment. Whether you need to recharge your phone, stay connected with data, or handle your utility bills, we've got you covered.
            </p>
            
            <h5>Key Services:</h5>

            <li>Virtual top-up for mobile phones.</li>
            <li>Data bundle sales for internet connectivity.</li>
            <li>Utilities bill payment for convenience.</li>
            <li>Secure and automated backups.</li>
            <li>Stay connected and manage your bills seamlessly with DAGTECH CAP LIMITED.</li>      
            </div>
            <div className='row-right'>
            <img src={telecom} className='product-image' />

            </div>
        </div>
        </div>
    </section>

        );
    }    
export default TeleCommunication;    
import React, { Component,useState,useEffect } from 'react';
import webhosting from "./../../components/images/webhosting.png";
import { Link } from "react-router-dom";

  
function WebDevelopment() {


    return (
        <section
        name="products"
        className='products bg-white'
        >
            <div className='container'  >
            <div class="row flex-space-around products-row mobile-row-reverse">
            <div className='row-left'>
            <img src={webhosting} className='product-image' />
            </div>
            <div className='row-right' >
            <h5>Web/Mobile Development</h5>
            <h3>Turning Your Ideas into Digital Reality with DAGTECH CAP LIMITED</h3>
            <p>
            Unlock the potential of your business with our web and mobile development services. We take your ideas and turn them into user-friendly, functional, and visually appealing digital solutions. Whether you need a website, a mobile app, or both, we're here to transform your vision into reality.
            </p>
            <h5>Key Features:</h5>
            <li>Custom web development.</li>
            <li>Mobile app development.</li>
            <li>Responsive and user-friendly design.</li>
            <li>Testing and quality assurance.</li>
                  
            <p>
            Let DAGTECH CAP LIMITED bring your digital dreams to life with our web and mobile development services.
            </p>
            <p>
            At DAGTECH CAP LIMITED, our commitment is to provide exceptional services in cross-border payments, web hosting, telecommunications, and web/mobile development. We understand the diverse needs of our clients and strive to deliver reliable, secure, and innovative solutions to help you achieve your goals.
            </p>
            </div>
        </div>
        </div>
    </section>

        );
    }    
export default WebDevelopment;    
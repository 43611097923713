import React from 'react';
import logo from './images/logo2.png';
import { Link,useLocation } from 'react-router-dom';
export default function Footer(){

    const date= new Date();
    const location = useLocation();

    const handleSubmit = (e) => {
      e.preventDefault();

    }
    return(
        <div className="footer">

         <div className="footer-container">
            <div className='footer-left'>
                <img src={logo} />
                <p>
                A world of possibilities where web hosting, cross-border payments, and telecommunication converge seamlessly to transform your digital experience.
                </p>
                <div className='row footer-social'>
                    <a href=""><i className='fa fa-facebook s-link' /></a>
                    <a href=""><i className='fa fa-x s-link' /></a>
                    <a href=""><i className='fa fa-linkedin s-link' /></a>
                </div>
             </div>  
             <div className='footer-middle'>
                <h3>Quick Links</h3>
                <ul className="footer-links">
  <li>
    <Link className={`anchor footer-anchor ${location.pathname === '/' ? 'active' : ''}`} to="/">
      Home
    </Link>
  </li>
  <li>
    <Link className={`anchor footer-anchor ${location.pathname === '/about' ? 'active' : ''}`} to="/about">
      About
    </Link>
  </li>
  <li>
    <Link className={`anchor footer-anchor ${location.pathname === '/products' ? 'active' : ''}`} to="/products">
      Products
    </Link>
  </li>
  <li>
    <Link className={`anchor footer-anchor ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact">
      Contact
    </Link>
  </li>
  <li>
    <Link className={`anchor footer-anchor ${location.pathname === '/white-paper' ? 'active' : ''}`} to="/white-paper">
      White Paper
    </Link>
  </li>
  <li>
    <Link className={`anchor footer-anchor ${location.pathname === '/terms-and-conditions' ? 'active' : ''}`} to="/terms-and-condition">
      T&Cs
    </Link>
  </li>
</ul>
            </div>
            <div className='footer-right'>
                <h3>Newsletter Signup up</h3>
                <p>
                Sign up and receive exclusive offers.
                </p>
                <form onSubmit={handleSubmit}>
                <div className="row">
          <input
            type="email"
            id="lastName"
            name="email"
          />    
            <button type="submit" className="btn-primary news-letter-button">
              <i className="fas fa-paper-plane"></i>
            </button>
  
                </div>
                </form> 
            </div> 
         </div>   
       <p>&copy; {date.getFullYear()} DagTechCapLtd | All rights reserved</p> 
  </div>

        );
    }
import React from 'react'
import { Link,useLocation } from 'react-router-dom';


export default function Breadcrumb(props) {
  return (
    <div className='breadcrumb'>
        
        <div className='breadcrumb-left'>
            <div className=''>
            <Link
                className="breadcrumb-link"
                to="/"
                >
               Home /  
        </Link>
         <span>{props.page}</span>
        </div>     
       
        <h3>{props.title}</h3>
        </div>
        <div className='breadcrumb-right'>
        <img src={props.banner} className='breadcrumb-image' />
        </div>
        </div>
  )
}

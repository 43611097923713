import React, { Component, Fragment,useEffect } from "react";
import HomePage from "./components/Home";
import { BrowserRouter,Routes, Route } from "react-router-dom";
import "./components/css/styles.css";
import AnimatedCursor from "react-animated-cursor"
import Aos from "aos";
import "aos/dist/aos.css";
import NotFoundPage from "./components/Notfound";
import AboutUs from "./components/About";
import ProductScreen from "./components/Products";
import TeamScreen from "./components/Team";
import ContactUs from "./components/Contact";


function App() {  
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 200 });
  }, []);

  return (
    <div>
 <AnimatedCursor
  innerSize={8}
  outerSize={35}
  innerScale={1}
  outerScale={1.7}
  outerAlpha={0}
  outerStyle={{
    border: '2px solid #7543E3'
  }}
  innerStyle={{
    backgroundColor: '#7543E3'
  }}
/>
   <BrowserRouter>
        
        <Routes>
        <Route path="/" element={ <HomePage />} />
        <Route path="/about" element={ <AboutUs />} />
        <Route path="/products" element={ <ProductScreen />} />
        <Route path="/team" element={ <TeamScreen />} />
        <Route path="/contact" element={ <ContactUs />} />

        <Route
        path="*"
        element={<NotFoundPage />}
        />
        </Routes>
        </BrowserRouter>
</div>
  );
}

export default App;

import React, { Component,useState,useEffect } from 'react';
import cbpayment from "./../../components/images/cbpayment.png";
import { Link } from "react-router-dom";

  
function CrossBorderPayment() {


    return (
        <section
        name="products"
        className='products bg-white'
        >
            <div className='container'  >
            <div class="row flex-space-around products-row mobile-row-reverse">
            <div className='row-left' >
            <img src={cbpayment} className='product-image' /> 
            </div>
            <div className='row-right'>
            <h5>Cross-Border Payment Solution</h5>
            <h3>Effortless Cross-Border Payments with DAGTECH CAP LIMITED</h3>
            <p>
            At DAGTECH CAP LIMITED, we understand the importance of smooth cross-border payments. Our Cross-Border Payment Solution is designed to make international money transfers secure, convenient, and cost-effective. Whether you're supporting family abroad or managing global business transactions, trust us to simplify the process and ensure your funds reach their destination with ease.
            </p>
            <h5>Key Features:</h5>

            <li>Secure transactions with robust encryption.</li>
            <li>Multiple payment options, including bank transfers, cards, and digital wallets.</li>
            <li>Competitive currency exchange rates.</li>
            <li>Transparent fee structure.</li>
            <li>Real-time tracking and notifications.</li>
            <li>Compliance with international regulations.</li>
            <li>24/7 customer support.</li> 
            <li>User-friendly mobile app.</li> 
            <li>Multilingual support.</li> 
            <li>Experience the future of cross-border payments with DAGTECH CAP LIMITED.</li>     
            </div>
        </div>
        </div>
    </section>

        );
    }    
export default CrossBorderPayment;    
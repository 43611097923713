import React from "react";
import NavBar from "./Navbar";
import Welcome from "./Home/Welcome";
import About from "./Home/About";
import Team from "./Home/Team";
import Partners from "./Home/Partners";
import CallToAction from "./Home/CallToAction";
import Footer from "./Footer";
import Products from "./Home/Products";

function Home() {

  return (
    <React.Fragment>
        <div className="container">
            <NavBar />
            <Welcome />
            <About />
            <Products />
            <Team />
            <Partners />
            <CallToAction />
            <div className="home-clearfix"></div>
        </div>
        <Footer />      
    </React.Fragment>
  );
}

export default Home;
import React from 'react'
import {useNavigate,useLocation,Link } from 'react-router-dom';
import notfound from './images/hero.png';

function NotFoundPage() {

  const navigate=useNavigate();
  const location =useLocation();


  return (
    <div className="notfound-page-container">
  <img src={notfound} alt="" />
  <h1>Page Not Found</h1>
  <p>Aww...don’t cry. It’s just a <br /> 404 error</p>

<div className='notfound-page-bottom'>
<Link to="/" className="submit-button" >
    Get Started</Link>
</div>
 

    </div>
  );
}

export default NotFoundPage;

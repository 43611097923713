import React from 'react';
import { Link,useLocation } from 'react-router-dom';
import logo from './../components/images/logo.png';

function MobileNav({ isOpen, onClose }) {
  const location = useLocation();

  return (
    <div className={`mobile-navigation ${isOpen ? 'open' : ''}`}>
      <div className='row-mobile-nav'>
       <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      {/* Close button to close the mobile navigation */}
      <div className="close-toggle-button" onClick={onClose}>
        <i className="fa fa-times" />
    </div>
    </div>
      <ul className="mobile-nav-column">
  <li>
    <Link className={`anchor ${location.pathname === '/' ? 'active' : ''}`} to="/" onClick={onClose}>
      Home
    </Link>
  </li>
  <li>
    <Link className={`anchor ${location.pathname === '/about' ? 'active' : ''}`} to="/about" onClick={onClose}>
      About
    </Link>
  </li>
  <li>
    <Link className={`anchor ${location.pathname === '/products' ? 'active' : ''}`} to="/products" onClick={onClose}>
      Products
    </Link>
  </li>
  <li>
    <Link className={`anchor ${location.pathname === '/team' ? 'active' : ''}`} to="/team" onClick={onClose}>
      Team
    </Link>
  </li>
  <li>
    <Link className={`anchor ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact" onClick={onClose}>
      Contact
    </Link>
  </li>
</ul>

  <div className="signup-button-mobile">
        <button>Sign Up</button>
    </div>

    </div>
  );
}

export default MobileNav;

import React, { Component,useState,useEffect } from 'react';
import webhosting from "./../../components/images/webhosting.png";
import { Link } from "react-router-dom";

  
function WebHosting() {


    return (
        <section
        name="products"
        className='products bg-grey'
        >
            <div className='container'  >
            <div class="row flex-space-around products-row">
            <div className='row-left' >
            <h5>WEB HOSTING</h5>
            <h3>Reliable Web Hosting Services for Your Online Presence</h3>
            <p>
            Your online presence starts with reliable web hosting, and DAGTECH CAP LIMITED offers hosting services that are second to none. Whether you're a small business, blogger, or a large enterprise, our hosting solutions provide the speed, security, and support you need to keep your website running smoothly.
            </p>
            
            <h5>Key Features:</h5>

            <li>High-performance servers.</li>
            <li>24/7 technical support.</li>
            <li>Scalability to meet your needs.</li>
            <li>Secure and automated backups.</li>
            <li>User-friendly control panel.</li>
            <li>Competitive pricing.</li>
            <li>Elevate your online presence with DAGTECH CAP LIMITED's web hosting services.</li>           
            </div>
            <div className='row-right'>
            <img src={webhosting} className='product-image' />

            </div>
        </div>
        </div>
    </section>

        );
    }    
export default WebHosting;    
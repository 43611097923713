import React from "react";
import NavBar from "../Navbar";
import Footer from "../Footer";
import AboutFirstBlock from "./FirstBlock";
import AboutSecondBlock from "./SecondBlock";
import Mission from "./Mission";
import Commitment from "./Commitment";
import Breadcrumb from "../Breadcrumb";
import banner from './../images/team-b.png';

function AboutUs() {

  return (
    <React.Fragment>
        <div className="container-pages">
            <NavBar />
            <Breadcrumb page={'About Us'} title={'Who We Are'} banner={banner}  />
           <AboutSecondBlock />
           <Mission />
           <Commitment />
        </div>
        <Footer />

    </React.Fragment>
  );
}

export default AboutUs;
import React, { useEffect, useState } from 'react';
import { Link,useLocation } from 'react-router-dom';
import logo from './../components/images/logo.png';
import MobileNav from './MobileNav';

function NavBar(props) {

  const location = useLocation();

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [navClicked, setNavClicked] = useState(false);

  const handleOpenSidebar = () => {
    setSidebarIsOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarIsOpen(false);
  };

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  
  return (
    <div className="navbar">
    <div className="logo">
      <Link to="/">
        <img src={logo} alt="Logo" />
      </Link>
    </div>

    <div className="nav-links">
    <ul className="nav-row">
  <li>
    <Link className={`anchor ${location.pathname === '/' ? 'active' : ''}`} to="/">
      Home
    </Link>
  </li>
  <li>
    <Link className={`anchor ${location.pathname === '/about' ? 'active' : ''}`} to="/about">
      About
    </Link>
  </li>
  <li>
    <Link className={`anchor ${location.pathname === '/products' ? 'active' : ''}`} to="/products">
      Products
    </Link>
  </li>
  <li>
    <Link className={`anchor ${location.pathname === '/team' ? 'active' : ''}`} to="/team">
      Team
    </Link>
  </li>
  <li>
    <Link className={`anchor ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact">
      Contact
    </Link>
  </li>
</ul>

    </div>

    {/* Mobile menu toggle button on the right side (shown on mobile, hidden on desktop) */}
    <div className="mobile-toggle-button">
      <div
        className={`open-sidebar btn-transparent ${sidebarIsOpen ? 'active' : ''}`}
        onClick={() => setSidebarIsOpen(true)}
      >
        <i className="fa fa-bars" />
      </div>
    </div>

    {sidebarIsOpen && (
 <MobileNav isOpen={sidebarIsOpen} onClose={handleCloseSidebar} />
    )}

   
    <div className="signup-button">
      <button>Sign Up</button>
    </div>
  </div>
  );
}

export default NavBar;
import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { animateScroll as scroll } from "react-scroll";
import about from "./../../components/images/about.png";
import { Link } from "react-router-dom";

const contentVariants = {
    initial: {
      translateY: "100vh",
      opacity: 0,
    },
  
    animate: {
      translateY: "0vh",
      opacity: 1,
      transition: {
        duration: 2,
        when: "beforeChildren",
        // staggerChildren: 0.4,
      },
    },
  };
  
  
function AboutSecondBlock() {

    const boxVariants = {
        hover: {
          scale: 1.06,
          transition: {
            type: "spring",
          },
        },
      };
      
    return (
     

        <section
        name="about"
        id="about"
        className='about about-section'
        >
            <div className='container about-container'>
            <h5>ABOUT</h5>
            <h3>Innovating the Future: The DAGTECH <br /> Journey</h3>
            <div class="row flex-space-around">
            <div className='about-left' >
            <p>
            DAGTECH CAP LIMITED is a forward-thinking technology company that was founded in August 2023. We are driven by a passion for harnessing the potential of cutting-edge technology to make a significant impact on industries and communities in Africa and beyond. 
            </p>
            <p>Our journey began with a vision to provide innovative technology solutions that could transform the way businesses and individuals connect, communicate, and conduct transactions. Over the years, we have made substantial strides in realizing this vision.</p>
            <p>We are a fast, reliable, and safe platform for web hosting, telecom services, and global payments. We are your one-stop hub for low-cost mobile banking with digital wallets, virtual top-ups, E-pins, and more. You can also have a secure online presence and easily make global payments.</p>
           
            </div>
            <div className='about-right'>
            <img src={about} className='about-image' />

            </div>
        </div>
        </div>
    </section>

        );
    }    
export default AboutSecondBlock;    
import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { animateScroll as scroll } from "react-scroll";
import product from "./../../components/images/product.png";
import { Link } from "react-router-dom";

const contentVariants = {
    initial: {
      translateY: "100vh",
      opacity: 0,
    },
  
    animate: {
      translateY: "0vh",
      opacity: 1,
      transition: {
        duration: 2,
        when: "beforeChildren",
        // staggerChildren: 0.4,
      },
    },
  };
  
  
function Products() {

    const boxVariants = {
        hover: {
          scale: 1.06,
          transition: {
            type: "spring",
          },
        },
      };
      
    return (
     

        <section
        name=""
        id="about"
        className='features'
        >
            <div className='container' >
            <div class="row flex-space-around">
            <div className='features-left' 
            data-aos="fade-zoom-in" 
            >
            <h5>Products</h5>
            <h3>Integrated Tech Solutions</h3>
            <p>
            Unlock the potential of a unified digital ecosystem with our integrated web hosting, cross border payment amd telecommunication solutions  
            </p>
            <img src={product} className='features-image' />
            <br />
            <Link
                className=""
                activeClass="active"
                to="/products"
                spy={true}
                smooth={true}
                delay={100}
                offset={0}
                duration={500}
                >
                <button className="bordered-rounded-button">Learn More <i className='fa fa-arrow-right' /></button>
                </Link>
            </div>
            <div className='features-right'>
              <div className='features-wrapper'>
                <h5>Web Hosting</h5>
                <p>Our web hosting services ensure that your online presence is stable, secure, and lightning
                fast, helping you make the most out of the digital landscape.
                </p>
              </div>
              <div className='features-wrapper'>
                <h5>Cross-Border Payment</h5>
                <p>We offer efficient and secure cross-border payment solutions, simplifying
                    international transactions for businesses and individual
                </p>
              </div>
              <div className='features-wrapper'>
                <h5>Telecommunication</h5>
                <p>We provide a wide range of telecommunication services, including Virtual Top Up,
                  E-Pins, Data Sales, and Recharge PINS, ensuring seamless connectivity and communication.
                </p>
              </div>
            </div>
        </div>
        </div>
    </section>

        );
    }    
export default Products;    
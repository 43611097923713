import React from "react";
import NavBar from "../Navbar";
import Footer from "../Footer";
import ContactForm from "./Form";
import Breadcrumb from "../Breadcrumb";
import banner from './../images/team-b.png';

function ContactUs() {

  return (
    <React.Fragment>
        <div className="container">
            <NavBar />
            <Breadcrumb page={'Contact us'} title={'Contact Us'} banner={banner}  />
             <div className="contact-wrapper">
                <div className="contact-left">
                  <h3>Let’s get in touch</h3>
                  <p>Don’t be afraid to say hello <br />to us!</p>  

                  <span>Phone</span>
                  <h6>+61422606715</h6> 
                  <h6>+2348056780988</h6>  

                  <span>Email</span>
                  <h6>dagtechcap@gmail.com</h6>  

                  <span>Website</span>
                  <h6>www.dagtechcap.com</h6>  
                </div>

                <div className="contact-right">
                   <ContactForm />
                  </div>
             </div>
        </div>
        <Footer />

    </React.Fragment>
  );
}

export default ContactUs;
import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { animateScroll as scroll } from "react-scroll";
import partner from "./../../components/images/partner.png";
import { Link } from "react-router-dom";

const contentVariants = {
    initial: {
      translateY: "100vh",
      opacity: 0,
    },
  
    animate: {
      translateY: "0vh",
      opacity: 1,
      transition: {
        duration: 2,
        when: "beforeChildren",
        // staggerChildren: 0.4,
      },
    },
  };
  
  
function CallToAction() {

    const boxVariants = {
        hover: {
          scale: 1.06,
          transition: {
            type: "spring",
          },
        },
      };
      
    return (
     

        <section
        name="team"
        id="about"
        className='cta'
        >
            <div className='cta-container'>
                <h5>GET PREMIUM ACCESS</h5>
            <h2>Join Our Digital <br /> Community</h2>
            <p>
            Sign up now to get cutting edge tech solutions, seamless cross border payments and powerful web hosting. Your digital journey starts here.
            </p>
            
            <Link
                className=""
                to="/about"
                >
                <button className="bordered-rounded-button cta-button">Learn More</button>
                </Link>
        </div>
    </section>

        );
    }    
export default CallToAction;    
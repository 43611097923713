import React from "react";
import NavBar from "../Navbar";
import Footer from "../Footer";
import Breadcrumb from "../Breadcrumb";
import banner from './../images/team-b.png';
import WebHosting from "./Webhosting";
import CrossBorderPayment from "./CrossBorderPayment";
import TeleCommunication from "./Telecommunication";
import WebDevelopment from "./WebDevelopment";

function ProductScreen() {

  return (
    <React.Fragment>
        <div className="container">
            <NavBar />
            <Breadcrumb page={'Products'} title={'Products'} banner={banner}  />
            <WebHosting />
            <CrossBorderPayment />
            <TeleCommunication />
            <WebDevelopment />
        </div>
        <Footer />

    </React.Fragment>
  );
}

export default ProductScreen;
import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link, animateScroll as scroll } from 'react-scroll';
import vision from "./../../components/images/vision.png";
import mission from "./../../components/images/mission.png";

const contentVariants = {
    initial: {
      translateY: 0, // Set the initial position at the resting point
    },
    animate: {
      translateY: [-20, 0, -20, 0], // Animate between -20 and 0 (up and down)
      transition: {
        yoyo: Infinity, // Continuously repeats the animation
        duration: 6, // Animation duration in seconds
      },
    },
  };
  
function Mission() {
    return (
    <section
    className='mission'
    >
          <h3>MISSION & VISION</h3>
            <p>We are driven by a passion for harnessing the <br />potential of cutting-edge technology to make 
            <br/>a significant impact on</p>
        <div class="row mission-wrapper">

            <div className='mission-left'> 
                    <img src={vision} className='polygon'/>
                    <div class="" >
                        <h5>Vision</h5>
                        <p>We are driven by a passion for harnessing the potential of cutting-edge technology to make a significant impact on</p>
                </div>
            </div>
            <div className='mission-right'>
                    <img src={mission} className='polygon'/>
                    <div class="" >
                        <h5>Mission</h5>
                        <p>We are driven by a passion for harnessing the potential of cutting-edge technology to make a significant impact on</p>
                </div>
            </div>
            </div>
       
    </section>
  );
}
export default Mission;